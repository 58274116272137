<template>
  <div v-if="isShowTopBanner && bannerData" id="desktop-top-banner" class="text-center @container"
    :data-popup-id="bannerData.no" style="position: relative;" :style="{ backgroundColor: bannerData.background_color }">
    <!--    리스폰시브일 때 모바일 탑 배너 시    -->
    <div v-if="bannerItem" id="mobile-top-banner" class="text-center flex @3xl:hidden justify-center"
      style="border-top: 10px solid rgb(239, 239, 239); border-bottom: 10px solid rgb(239, 239, 239);width:100%;"
      :style="{ backgroundColor: bannerItem?.background_color }">
      <div v-if="dDayCheck" class="px-[7px] py-[2px] @3xl:px-[10px] @3xl:py-[3px]"
        style="position: absolute;bottom: 10px;left: 0; background-color: #df3434;color: #fff;">
        {{ day }}
      </div>

      <span v-if="bannerItem.background" class="flex-grow mobile-top-banner-side"
        :style="computedBackground(bannerItem.background)" />
      <div>
        <MiscDYLink
          :to="bannerItem.pc_url.includes('/v/') ? bannerItem.pc_url.replace(fullDomain + '', '').replace('/v/', '/') : ''"
          :title="bannerItem.name">
          <img :src="bannerItem.bannerImage" style="max-width: 100%;max-height:79px;">
        </MiscDYLink>
      </div>

      <span v-if="bannerItem.background2" class="flex-grow mobile-top-banner-side"
        :style="computedBackground(bannerItem.background)" />
      <a v-if="bannerItem.bottom_msg" style="position: absolute; top: 0; right: 0; padding: 5px;"
        :style="{ color: bannerItem.font_color }" @click="closeTopBanner">
        <v-icon icon="fal fa-times" style="font-size: 12px;" />
      </a>
    </div>
    <!--    리스폰시브일 때 모바일 탑 배너 종    -->

    <!--    이쪽은 기본 데스크탑용 탑배너 시    -->
    <div v-if="bannerItem" class="hidden @3xl:flex justify-center" style="width: 100%;">
      <div v-if="bannerItem.background" class="flex-grow" style="background-repeat: repeat; background-position: right;"
        :style="computedBackground(bannerItem.background)" />
      <div style="position: relative;">
        <MiscDYLink v-if="bannerItem.pc_url"
          :to="bannerItem.pc_url.includes('/v/') ? bannerItem.pc_url.replace(fullDomain + '', '').replace('/v/', '/') : ''"
          :href="bannerItem.pc_url.includes('/v/') ? '' : bannerItem.pc_url" :title="bannerData.name">
          <img class="topBannerImage" :src="bannerItem.bannerImage" style="max-width: 100%;">
        </MiscDYLink>
        <div v-if="dDayCheck"
          style="position: absolute;bottom: 0;background-color: #df3434;color: #fff;padding:3px 10px 3px 10px;"
          class="left-0 @6xl:left-[-23px]">
          {{ day }}
        </div>
        <div v-if="dDayCheck" class="dDayContent @6xl:left-test left-[calc(50%+610px)] hidden @6xl:block"
          style="position: absolute;background-color: rgba(0, 0, 0, 0.5); padding: 9px 5px 7px;border-radius: 3px; bottom: 10px; transform: translateX(-50%);width:115px;">

          <span style="color: #fff;font-size: 12px;">
            <svg v-if="day != '오늘까지!'" class="inline-block" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="17px"
              height="17px" viewBox="0 0 97.16 97.16"
              style="enable-background:new 0 0 97.16 97.16;vertical-align: top;margin-right: 4px;margin-top: 5px;"
              xml:space="preserve">
              <g>
                <g>
                  <path
                    d="M48.58,0C21.793,0,0,21.793,0,48.58s21.793,48.58,48.58,48.58s48.58-21.793,48.58-48.58S75.367,0,48.58,0z M48.58,86.823    c-21.087,0-38.244-17.155-38.244-38.243S27.493,10.337,48.58,10.337S86.824,27.492,86.824,48.58S69.667,86.823,48.58,86.823z"
                    fill="#FFFFFF"></path>
                  <path
                    d="M73.898,47.08H52.066V20.83c0-2.209-1.791-4-4-4c-2.209,0-4,1.791-4,4v30.25c0,2.209,1.791,4,4,4h25.832    c2.209,0,4-1.791,4-4S76.107,47.08,73.898,47.08z"
                    fill="#FFFFFF"></path>
                </g>
              </g>
              <g> </g>
              <g> </g>
              <g> </g>
              <g> </g>
              <g> </g>
              <g> </g>
              <g> </g>
              <g> </g>
              <g> </g>
              <g> </g>
              <g> </g>
              <g> </g>
              <g> </g>
              <g> </g>
              <g> </g>
            </svg>
            <span class="inline-block" style="margin-right: 5px; font-size: 16px; font-weight: 500;">
              {{ day }}<span v-if="day !== '오늘까지!'">일</span>
            </span>
            <br>
            <span class=" dDayHMS" style="letter-spacing: -0.5px;">{{ hour }} : {{ minute }} : {{ second }} 남음!</span>
          </span>
        </div>
        <a v-if="bannerItem.bottom_msg" style="position: absolute; top: 0; right: 0; padding: 5px;"
          :style="{ color: bannerItem.font_color }" @click="closeTopBanner">
          <v-icon icon="fal fa-times" style="font-size: 12px;" />
        </a>
      </div>
      <div v-if="bannerItem.background2" class="flex-grow" style="background-repeat: repeat;"
        :style="computedBackground(bannerItem.background2)" />
    </div>
    <!--    이쪽은 기본 데스크탑용 탑배너 종    -->
  </div>
</template>

<script setup lang='ts'>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import dayjs from 'dayjs';
import { useDYAuth } from '~/modules/nuxt3-module-doyac/composables/auth';
import { useDYAppConfig } from '~/modules/nuxt3-module-doyac/composables/config';
import { useDYBanner } from '~/modules/nuxt3-module-doyac/composables/dy-banner';
import { useDYComponent } from '~/modules/nuxt3-module-doyac/composables/dy-component';
import { useDYWindowStore } from '~/modules/nuxt3-module-doyac/stores/window';
import { useDYBannerStore } from '~/modules/nuxt3-module-doyac/stores/banner';
import { useDYUserStore } from '~/modules/nuxt3-module-doyac/stores/user';

library.add(faTimes)

const emit = defineEmits<{
  (e: 'closeTopBanner'): void
}>()

const dyComponent = useDYComponent()
const windowStore = useDYWindowStore()
const { hasAuth } = useDYAuth()
const { dbDomain, fullDomain } = useDYAppConfig()
const { priorityCheck } = useDYBanner()
const bannerStore = useDYBannerStore()
const userStore = useDYUserStore()

// -------------------------인터페이스

// -------------------------데이터

const props = withDefaults(defineProps<{
  type?: 'small' | 'big' | 'auto'
}>(), {
  type: () => 'auto'
})

const day = ref('')
const hour = ref('')
const minute = ref('')
const second = ref('')

const ddayInterval = ref()
const showTopBanner = ref(true) // 유저가 배너 끄기 눌러서 닫았을 경우
const isAdmin = hasAuth('admin')

// -------------------------컴퓨티드

const bannerData = computed(() => { // dyBanner의 단위. 이 안의 여러 엔트리들이 있을수는 있음
  const bigBanner = bannerStore.bannerData?.popup_top && bannerStore.bannerData?.popup_top.length > 0 ? bannerStore.bannerData.popup_top[0] : undefined
  const smallBanner = bannerStore.bannerData?.popup_mobile_top && bannerStore.bannerData?.popup_mobile_top.length > 0 ? bannerStore.bannerData.popup_mobile_top[0] : undefined
  let currentBanner = windowStore.windowWidth >= 767 ? bigBanner : smallBanner
  if (props.type === 'small') currentBanner = smallBanner
  if (props.type === 'big') currentBanner = bigBanner

  if (!currentBanner) return undefined
  if (!currentBanner.available) return undefined
  if (currentBanner.admin_only && !isAdmin) return undefined

  return currentBanner
})

const bannerEntry = computed(() => { //해당 배너의 개별 엔트리. 
  if ((userStore.isSignedIn && bannerData.value) || bannerData.value) { //탑배너의 경우에는 무조건 하나의 엔트리만 노출될수 있어서 0번 픽
    const checkedEntries = bannerData.value ? priorityCheck(bannerData.value.entries) : undefined
    if(!checkedEntries || (checkedEntries && checkedEntries.length < 1)){
      closeTopBanner()
    }
    return checkedEntries && checkedEntries[0] ? checkedEntries[0] : undefined
  }

  return undefined
})

const bannerItem = computed(() => {
  return bannerEntry.value?.bannerItem
})

const dDayCheck = computed(() => {
  // console.log('배너엔트리', bannerEntry.value)
  if (bannerEntry.value === undefined) return false
  // console.log('디데이', endDate.value > nowDate.value && bannerEntry.value.dDayOpen === 'y')
  return endDate.value > nowDate.value && bannerEntry.value.dDayOpen === 'y'
})

const endDate = computed(() => {
  return dayjs(bannerEntry.value?.end_date).hour(23).minute(59).second(59)
})

const isShowTopBanner = computed(() => {
  const banBanner = useCookie('SHOW_FLAT_AD' + bannerData.value?.no).value
  if (banBanner) {
    emit('closeTopBanner')
    return false
  } else return true
})

const mobileDdayCounter = computed(() => { // 모바일용 카운터는 pc와는 조금 다르기 때문
  if (!dDayCheck.value || !endDate.value) { return undefined }

  const interVal = endDate.value.get('date') - nowDate.value.get('date')
  return interVal > 0 ? 'D-' + interVal : '오늘까지!'
})

const nowDate = computed(() => {
  return dayjs()
})

// -------------------------메서드

const bannerCheck = () => {
  // console.log('배너체크')
  counter()
}

const closeTopBanner = () => {
  const SHOW_FLAT_AD = useCookie('SHOW_FLAT_AD' + bannerData.value?.no, { maxAge: 86400 })
  SHOW_FLAT_AD.value = 'N'

  emit('closeTopBanner')
}

const computedBackground = (background: string) => {
  return background.startsWith('#') ? { backgroundColor: background } : { backgroundImage: `url('/upFolder/${dbDomain}/upFolder/upFile/BANNER/${background}')` }
}

const counter = () => {
  // console.log(111, dDayCheck.value)
  if (!dDayCheck.value) return
  if (ddayInterval.value) clearInterval(ddayInterval.value)
  // console.log(222)
  ddayInterval.value = setInterval(() => {
    const now = dayjs()
    const distance = endDate.value.unix() - now.unix()

    let d = Math.floor(distance / 86400)
    let h = Math.floor((distance % 86400) / 3600)
    let m = Math.floor((distance % 3600) / 60)
    let s = Math.floor((distance % 60) / 1)

    if (d >= 0) {
      if (d < 1) {
        day.value = '오늘까지!'
      } else {
        day.value = 'D - ' + d + ''
      }
      if (h < 10) {
        hour.value = '0' + h
      } else {
        hour.value = '' + h;
      }
      if (m < 10) {
        minute.value = '0' + m
      } else {
        minute.value = '' + m;
      }
      if (s < 10) {
        second.value = '0' + s
      } else {
        second.value = '' + s;
      }
    }
  }, 1000)
}

// -------------------------훅

if (process.client) {
  watch(dDayCheck, async (val) => {
    if (val) await bannerCheck()
  })
}

dyComponent.$onActivated(async () => {
  await nextTick()
  
  if (dDayCheck.value) await bannerCheck()
})

dyComponent.$onDeactivated(() => {
  if (ddayInterval.value) clearInterval(ddayInterval.value)
})

</script>

<style lang='scss' scoped>//</style>