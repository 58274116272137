import { defineStore } from "pinia"
import { type DYBanners } from "../composables/dy-banner"

export const useDYBannerStore = defineStore('nuxt3-module-doyac-banner', () => {
  
  const bannerData = ref<DYBanners>()

  return {
    bannerData,
  }
})